import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import { WhitePapers, ProjectCards } from '../data/resources-data';
import Layout from '../components/GatsbyLayout';
import ProjectCard from '../components/ProjectCard';
import Helmet from '../components/Helmet';
import Subtitle from '../components/Subsection/Subtitle';
import CaseStudyBanner from '../components/CaseStudyBanner';
import WhitePaper from '../components/WhitePaper';
import Article from '../components/Article';

const renderHelmet = () => (
  <Helmet
    title="Resources - Open Source Contributions from Industry Thought Leaders"
    description="We keep up with the latest trends and provide support to the open-source
     community of software development."
    keywords="Cluj JS, Cluj Javascript, Cluj DevOps, Cluj UX, React, React Native, Angular,
    Ionic, iOS, Android, NodeJS, PHP, AWS, Devops, Security, QA, Automation, Blockchain"
    canonical
    canonicalPage="resources"
  />
);

const Resources = ({ location, data: gatsbyData }) => {
  const fluidSrc = gatsbyData.file.childImageSharp.fluid;

  return (
    <Layout location={location}>
      {renderHelmet()}
      <div className="main main__inner resources">
        <CaseStudyBanner
          category="Resources"
          title="Free resources and open source tools from our top experts"
          description="Here at MCRO we’re all about giving back to the community. More exciting projects ahead,
              so stay tuned!"
          fluid={fluidSrc}
          buttonText="Visit our GitHub Repository"
          icon=" fa fa-github"
          onButtonPress="https://github.com/MCROEngineering"
        />
        <div className="resources__section">
          <div className=" resources__subtitle resources__subtitle-papers">
            <Subtitle
              titleType="withDot"
              withoutLine
              title="White papers"
            />
          </div>
          <div className="papers">
            {
                WhitePapers.map(paper => (
                  <WhitePaper
                    key={paper.title}
                    title={paper.title}
                    shortDescription={paper.shortDescription}
                    image={paper.image}
                    comingSoon={paper.comingSoon}
                    page={paper.page}
                  />
                ))
              }
          </div>
        </div>
        <div className="resources__section">
          <div className="resources__subtitle">
            <Subtitle
              titleType="withDot"
              withoutLine
              title="Open source"
            />
          </div>
          <div className="cards">
            {ProjectCards.map(project => (
              <ProjectCard
                key={project.title}
                link={project.link}
                secondLink={project.secondLink}
                docsLink={project.docsLink}
                internalLink={project.internalLink}
                title={project.title}
                btnName={project.btnName}
                secondBtn={project.secondBtn}
                docsBtnName={project.docsBtnName}
                content={project.content}
                image={project.image}
              />
            ))}
          </div>
        </div>
        <div className="resources__section">
          <div className="resources__subtitle">
            <Subtitle
              titleType="withDot"
              withoutLine
              title="Featured articles"
            />
            <div className="articles">
              {gatsbyData.allMarkdownRemark.edges
                .filter(edge => edge.node.frontmatter.featured)
                .slice(0, 3)
                .map(edge => (
                  <Article
                    key={edge.node.frontmatter.title}
                    title={edge.node.frontmatter.title}
                    readingTime={edge.node.timeToRead}
                    date={edge.node.frontmatter.date}
                    path={edge.node.frontmatter.path}
                    imageFluid={edge.node.frontmatter.mainImage.childImageSharp.fluid}
                  />
                ))
              }
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

Resources.propTypes = {
  location: PropTypes.shape({}).isRequired,
  data: PropTypes.shape({})
};

Resources.defaultProps = {
  data: null
};

export default Resources;

export const query = graphql`
  query MyQuery {
  allMarkdownRemark {
    edges {
      node {
        frontmatter {
          featured
          mainImage {
            absolutePath
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
          date(formatString: "MMM DD, YYYY")
          title
          path
        }
        id
        timeToRead
      }
    }
  }
  file(relativePath: {eq: "resources-header-image.png"}) {
    childImageSharp {
      fluid(maxWidth: 550) {
          ...GatsbyImageSharpFluid
        }
    }
  }
}`;
