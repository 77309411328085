import React from 'react';
import { Link } from 'gatsby';

const WhitePaper = ({ title, shortDescription, image, comingSoon, page }) => (
  <React.Fragment>
    {comingSoon ? (
      <div className="white-paper">
        <div className="img-container">
          <div className="white-paper__coming-soon" tabIndex="0">Coming soon</div>
          <div className="white-paper__cover" />
          <img className="white-paper__image" src={image} alt={title} title={title} />
        </div>
        <h2 className="white-paper__title" tabIndex="0">{title}</h2>
        <div className="white-paper__description" tabIndex="0">{shortDescription}</div>
      </div>
    )
      : (
        <Link to={`/resources/${page}/`} className="white-paper">
          <div className="img-container">
            <img className="white-paper__image" src={image} alt={title} title={title} />
          </div>
          <h2 className="white-paper__title" tabIndex="0">{title}</h2>
          <div className="white-paper__description" tabIndex="0">{shortDescription}</div>
        </Link>
      )
    }
  </React.Fragment>
);

export default WhitePaper;
